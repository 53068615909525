<template>
  <!-- index.vue -->

  <v-app
    id="home"
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    :class="{
      'app-admin-wrap-layout-1 sidebar-mini': getThemeMode.verticalSidebarMini,
      'sidebar-close': !getThemeMode.verticalSidebarDrawer,
    }"
  >
    <profile-options :callDataProp="callData">
      <!-- -->
    </profile-options>

    <menu-centers class="">
      <!-- -->
    </menu-centers>
    <!-- <sub-header>
      
    </sub-header> -->
    <!-- Sizes your content based upon application components -->

    <base-view />
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "IndexSilver",
  components: {
    BaseView: () => import("./View"),
    ProfileOptions: () => import("./ProfileOptions"),
    MenuCenters: () => import("./MenuCenters"),
    // SubHeader: () => import("./SubHeader"),
  },

  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      callData: (state) => state.loggedUserSilver.callData,
    }),

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style lang="scss" scoped></style>
